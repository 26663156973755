<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('users.roles') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <RoleForm :role="{}" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import RoleForm from "@/components/admin/user/roles/RoleForm.vue";

export default {
  name : "AddRole",
  components: { NavBar, RoleForm },
  data: function() {
    return {
      roles: {},
      showOverlay: true
    }
  },
  created: function() {
    this.$http.get('/cms/api/users/roles/all', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.roles = r.data;
    }).catch(e => { this.handleError(e); })
    this.showOverlay = false
  }
}
</script>
<style></style>
